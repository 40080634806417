import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  Field,
  NextImage,
  RichText,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import Video from '../../../components/shared/Video/Video';
import {
  StAccordion,
  StAccordionDetails,
  StAccordionSummary,
  StImageMediaContainer,
  StVideoMediaContainer,
} from './Accordion.styles';

type AccordionDetails = {
  fields: {
    accordionTitle: Field<string>;
    accordionDescription: Field<string>;
    accordionAdditionalDescription?: any;
    imageUrl?: any;
    videoUrl?: any;
  };
};

type AccordionProps = {
  expandOnlyOneAccordion: boolean;
  hideExpandAllButton: boolean;
  sectionsList: AccordionDetails[];
  expandAllText: string;
  collapseAllText: string;
  children: (
    singleLineHeading: boolean,
    handleExpand: () => any,
    showExpandIcon: boolean,
    showExpandIconText: string
  ) => JSX.Element;
  headingWithIconVariant?: string;
};

const Accordion = (props: AccordionProps): JSX.Element => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const [expandAll, setExpandAll] = useState(
    new Array<boolean>(props.sectionsList.length).fill(false)
  );

  const singleLineHeading = props?.headingWithIconVariant === 'ImageUp' ? false : true;

  const [showExpandIcon, setShowExpandIcon] = useState(true);
  const [showExpandIconText, setShowExpandIconText] = useState(props.expandAllText);

  const handleExpand = () => {
    if (expandAll.some((data) => data === false)) {
      setExpandAll((prevState) => {
        const expandedData = prevState.fill(true);
        return [...expandedData];
      });
    } else {
      setExpandAll((prevState) => {
        const expandedData = prevState.fill(false);
        return [...expandedData];
      });
    }
  };

  useEffect(() => {
    if (sitecoreContext?.pageEditing) {
      setExpandAll(new Array<boolean>(props.sectionsList.length).fill(true));
    } else {
      setExpandAll(new Array<boolean>(props.sectionsList.length).fill(false));
    }
  }, [props.sectionsList]);

  useEffect(() => {
    if (expandAll.some((data) => data === false)) {
      setShowExpandIcon(true);
      setShowExpandIconText(props.expandAllText);
    } else {
      setShowExpandIcon(false);
      setShowExpandIconText(props.collapseAllText);
    }
  }, [expandAll, props.collapseAllText, props.expandAllText]);

  function renderMediaItem(accordionSection: any) {
    const { videoUrl, imageUrl, autoPlayVideo } = accordionSection?.fields;

    if (
      videoUrl?.value?.href !== '' &&
      videoUrl?.value?.href !== undefined &&
      videoUrl?.value?.href !== null
    ) {
      const videoProps = {
        fields: {
          fields: {
            videoUrl: { value: videoUrl?.value?.href },
            autoPlayVideo,
          },
        },
      };

      return (
        <StVideoMediaContainer data-testid="video-container">
          <Video {...videoProps} />
        </StVideoMediaContainer>
      );
    } else if (
      imageUrl?.value?.src !== '' &&
      imageUrl?.value?.src !== undefined &&
      imageUrl?.value?.src !== null
    ) {
      return (
        <StImageMediaContainer data-testid="image-container">
          <NextImage field={imageUrl} height={382} width={686} />
        </StImageMediaContainer>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      {props.children(singleLineHeading, handleExpand, showExpandIcon, showExpandIconText)}
      {props?.sectionsList &&
        props?.sectionsList.map((accordionSection, index) => {
          const key = `${accordionSection?.fields?.accordionTitle?.value}-${index}`;
          return (
            <StAccordion
              key={key}
              disableGutters={true}
              expanded={expandAll[index] || false}
              onChange={() => {
                setExpandAll((prevState) => {
                  const currentState: boolean = prevState[index];
                  if (!props.expandOnlyOneAccordion) {
                    expandAll[index] = !currentState;
                  } else {
                    for (let i = 0; i < expandAll.length; i++) {
                      if (i === index) {
                        expandAll[i] = !currentState;
                      } else {
                        expandAll[i] = false;
                      }
                    }
                  }

                  return [...expandAll];
                });
              }}
            >
              <StAccordionSummary
                expandIcon={<ChevronRightIcon sx={{ height: '28px', width: '27px' }} />}
              >
                <Typography
                  variant="accordionTitle"
                  sx={{
                    [singleLineHeading ? theme.breakpoints.down(540) : theme.breakpoints.down(478)]:
                      {
                        fontSize: '1rem',
                        lineHeight: '1.69rem',
                      },
                  }}
                >
                  <Text field={accordionSection?.fields?.accordionTitle}></Text>
                </Typography>
              </StAccordionSummary>
              <StAccordionDetails>
                <RichText
                  field={accordionSection?.fields?.accordionDescription}
                  style={{ marginBottom: '12px' }}
                />
                {renderMediaItem(accordionSection)}
                {sitecoreContext?.pageEditing && (
                  <RichText field={accordionSection?.fields?.accordionAdditionalDescription} />
                )}
                {!sitecoreContext?.pageEditing &&
                  accordionSection?.fields?.accordionAdditionalDescription?.value !== '' && (
                    <RichText field={accordionSection?.fields?.accordionAdditionalDescription} />
                  )}
              </StAccordionDetails>
            </StAccordion>
          );
        })}
    </>
  );
};

export default Accordion;
