import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import Accordion from '../../components/shared/Accordion/Accordion';
import IconWithTitle from '../../components/shared/IconWithTitle/IconWithTitle';
import { ComponentProps } from '../../lib/component-props';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CollapseExpandIcon from '../../components/shared/Accordion/CollapseExpandIcon';
import {
  StDTPGridContainer,
  StDTPWrapper,
} from '../../components/shared/Container/Container.styles';

type Accordion = {
  fields: {
    accordionTitle: Field<string>;
    accordionDescription: Field<string>;
  };
};

type AccordionPanelProps = ComponentProps & {
  fields: {
    title: Field<string>;
    titleIcon: ImageField;
    iconWithTitleVariant: { value: string };
    hideExpandAllButton: Field<boolean>;
    expandOnlyOneAccordion: Field<boolean>;
    expandAllText: Field<string>;
    collapseAllText: Field<string>;
    sectionsList: Accordion[];
    ariaLabel: Field<string>;
  };
};

const AccordionPanel = (props: AccordionPanelProps): JSX.Element => {
  const theme = useTheme();
  const isSmallerDevice = useMediaQuery(theme.breakpoints.down(600));

  if (!props?.fields) return <></>;
  const IconWithTitleProps = {
    titleIcon: props?.fields?.titleIcon,
    title: props?.fields?.title,
    iconWithTitleVariant: props?.fields?.iconWithTitleVariant?.value
      ? props?.fields?.iconWithTitleVariant?.value
      : 'ImageUp',
  };
  const accordionProps = {
    expandOnlyOneAccordion: props?.fields?.expandOnlyOneAccordion
      ? props?.fields?.expandOnlyOneAccordion?.value
      : false,
    hideExpandAllButton: props?.fields?.hideExpandAllButton
      ? props?.fields?.hideExpandAllButton?.value
      : false,
    sectionsList: props.fields?.sectionsList,
    expandAllText: props.fields?.expandAllText?.value,
    collapseAllText: props.fields?.collapseAllText?.value,
    headingWithIconVariant: props?.fields?.iconWithTitleVariant
      ? props?.fields?.iconWithTitleVariant?.value
      : 'ImageUp',
  };
  return (
    <Box sx={{ background: theme.palette.backgroundColorType3.main }}>
      <StDTPWrapper id={'faqs'} bgColor={theme.palette.backgroundColorType3.main}>
        <StDTPGridContainer bgColor={theme.palette.backgroundColorType3.main}>
          <Box sx={{ paddingBlock: '1.5rem' }}>
            <Accordion {...accordionProps}>
              {(singleLineHeading, handleExpand, showExpandIcon, showExpandIconText) => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: accordionProps?.hideExpandAllButton
                      ? 'center'
                      : 'space-between',
                    alignItems: 'center',
                    paddingBottom: isSmallerDevice ? '0.5rem' : '1rem',
                  }}
                >
                  {accordionProps?.hideExpandAllButton ? (
                    <>
                      <IconWithTitle {...IconWithTitleProps} />
                    </>
                  ) : (
                    <>
                      {!isSmallerDevice && (
                        <Box sx={{ visibility: 'hidden' }}>
                          <CollapseExpandIcon
                            singleLineHeading={singleLineHeading}
                            handleClick={handleExpand}
                            showExpandIcon={showExpandIcon}
                            showExpandIconText={showExpandIconText}
                            ariaLabel={props?.fields?.ariaLabel?.value}
                          />
                        </Box>
                      )}
                      <IconWithTitle {...IconWithTitleProps} />
                      <CollapseExpandIcon
                        handleClick={handleExpand}
                        singleLineHeading={singleLineHeading}
                        showExpandIcon={showExpandIcon}
                        showExpandIconText={showExpandIconText}
                        ariaLabel={props?.fields?.ariaLabel?.value}
                      />
                    </>
                  )}
                </Box>
              )}
            </Accordion>
          </Box>
        </StDTPGridContainer>
      </StDTPWrapper>
    </Box>
  );
};

export default AccordionPanel;
